<template>
  <div class="pages-manager">
    <div class="mb-3 text-right">
      <b-button
        variant="primary"
        @click="handleAction('create')"
        :disabled="loading || deleting"
      >
        Añadir Nueva Página
      </b-button>
    </div>

    <b-card>
      <b-card-body class="position-relative">
        <!-- Spinner mientras se cargan las páginas -->
        <div v-if="loading" class="loading-container">
          <b-spinner variant="primary" label="Cargando..."></b-spinner>
        </div>

        <!-- Mostrar error si hay uno -->
        <b-alert v-else-if="error" variant="danger" show>
          {{ error }}
        </b-alert>

        <!-- Lista de páginas -->
        <b-list-group v-else flush>
          <b-list-group-item
            v-for="(page, index) in pages"
            :key="index"
            class="cursor-pointer d-flex justify-content-between align-items-center"
            action
          >
            <span class="page-title">{{ page.title }}</span>

            <div>
              <b-button
                variant="warning"
                size="sm"
                class="mr-1"
                @click.stop="editPage(page)"
                :disabled="loading || deleting"
              >
                Editar
              </b-button>

              <b-button
                variant="danger"
                size="sm"
                :disabled="loading || deleting"
                @click.stop="deletePage(page._id)"
              >
                <span >Eliminar</span>
              </b-button>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      pages: [],
      loading: false,
      deleting: false,
      error: null,
    };
  },
  methods: {
    async fetchPages() {
      this.loading = true;
      this.error = null;
      try {
        const response = await axios.get(`${process.env.VUE_APP_URL}/pages`);
        this.pages = response.data.pages;
      } catch (error) {
        this.error = "Error al cargar las páginas. Inténtalo de nuevo.";
      } finally {
        this.loading = false;
      }
    },

    handleAction(mode, data = null) {
      const id = mode === "create" ? null : data._id;
      this.$router.push({ name: "PageEditor", params: { mode, id } });
    },

    editPage(page) {
      this.handleAction("edit", page);
    },

    async deletePage(pageId) {
      this.deleting = true;
      try {
        await axios.delete(`${process.env.VUE_APP_URL}/pages/${pageId}`);
        this.$toast.success("Página eliminada con éxito.");
        await this.fetchPages();
      } catch (error) {
        this.$toast.error("Error al eliminar la página.");
      } finally {
        this.deleting = false;
      }
    },
  },
  mounted() {
    this.fetchPages();
  },
};
</script>

<style scoped>
.pages-manager {
  padding: 1rem;
}

.loading-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.page-title {
  font-weight: 600;
}
</style>

<template>
  <div>
    <b-row v-if="singleButton">
      <b-col>
        <!-- Styled -->
        <b-form-file
            accept="*/*"
            v-model="imgFile"
            :placeholder="$t('upload_image')"
            drop-placeholder="Drop file here..."
            @change="saveImage">
        </b-form-file>
        <!--<div class="mt-1 font-small-3">Selected file: {{ imgFile ? imgFile.name : '' }}</div>-->
      </b-col>
    </b-row>
      
  <div v-else>
    <div v-if="preview && value" class="preview-container">
      <b-button
        variant="danger"
        style="
          padding: 5px 8px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        "
        @click="$emit('changeImage', null)"
        >X</b-button
      >
      <img
        class="preview"
        v-if="valueLocal"
        :src="valueLocal"
        :width="preview_width ? preview_width : '100%'"
        :height="preview_height ? preview_height : 'auto'"
        alt="IMG PREVIEW"
      />
    </div>
    <b-dropdown id="ddown1"
                :menu-class="[{'dropdown-height' : isArray(images.routes) && images.routes.length > 0 }]"
                :text="text" class="w-100" variant="primary"
    >
        <b-dropdown-item
        v-for="(item, i) in images.routes"
        :key="i"
        :active="valueLocal == item || verifyValue(item)"
        @click="$emit('changeImage', item)"
      >
        <img
          :src="item"
          alt=""
          border="0"
          height="50"
          width="65"
          style="max-width: 130px"
        />
      </b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item @click="$refs.file.click()"> Add image </b-dropdown-item>
    </b-dropdown>
    <input
      type="file"
      ref="file"
      style="display: none"
      @change="saveImage"
      multiple
    />
  </div>

    <b-modal
      v-model="modal.show"
      no-close-on-esc
      no-close-on-backdrop
      size="md"
      cancel-variant="outline-secondary"
      centered
      title="Uploading image"
      hide-footer
      hide-header-close
    >
      <b-card>
        <div class="d-flex flex-column flex-wrap align-items-center justify-content-center pa-3">
          <div class="mb-1 font-weight-bold font-medium-2">{{ modal.subtitle }}</div>
          <b-progress class="w-100" :value="modal.percent" :max="100" show-progress animated></b-progress>
        </div>
      </b-card>
    </b-modal>
      
  </div>
</template>
<script>
import {
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BButton, BCard, BModal,
} from "bootstrap-vue";
import axios from "@axios";
import {isArray} from "vue-context/src/js/utils";

export default {
  components: {
      BModal, BCard,
    BDropdown,
    BDropdownItem,
    BButton,
    BDropdownDivider,
  },
  props: {
    value: {},
    text: {},
    path: {},
    images: {},
    singleButton: {},
    preview: {},
    preview_width: {},
    preview_height: {},
    sizeValidation: {},
    acceptOnly: {},
    allowMany: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      APP_URL: process.env.VUE_APP_URL,
      modal : {
          show : false,
          subtitle : "",
          percent : 0
      },
      imgFile : null
    };
  },
  model: {
    prop: "value",
    event: "value-changed",
  },
  computed: {
    valueLocal: {
      get() {
        return this.value;
      },
      set(newval) {
        this.$emit("value-changed", newval);
      },
    },
  },
  methods: {
      isArray,
    async saveImage(event) {

      //files must be images
      // if (!event.target.files[0].type.startsWith("image/")) {
      //     const error = this.$t("invalid_file_type");
      //     console.log(error);
      //     this.$emit("error", error);
      //     return;
      // }
      
      setTimeout(() => {
        this.$emit("previewImage");
      }, 1000);
      
      if (event.target.files) {

        if (this.validateFormat(event.target.files[0].type) === false) {
          this.$emit("error", 'Incorrect format.' + event.target.files[0].type + ' type not allowed');
          return
        }

        let size = parseFloat(event.target.files[0].size / 1024).toFixed(2);
        let maxSize = 2048; // 2097152 byte
        if ( size > maxSize )
        {
          const error = this.$t("image_size_limit",{size})
          console.log(error)
          this.$emit("error",error);
          return;
        }
        
        //If there is validation of the image resolution, it will enter here
        const isValidate = await this.validateMediaRes(event)
        if (!isValidate) return
        
        const formData = new FormData();
        for (const e of event.target.files) {
          formData.append("images", e, e.name);
        }
        formData.append("path", this.path);

        console.log(formData)

        this.setModal({percent : 0, show : true})
        axios
          .post(
            `${this.APP_URL}/setImages/${this.$store.state.whitelabelCurrencyNabvar.whitelabel.client}`,
            formData,{
                  onUploadProgress: (progressEvent) => {
                      const loadPercentage = Math.round(
                          (progressEvent.loaded * 100) / progressEvent.total
                      );
                      this.setModal({percent : loadPercentage, show : true})
                  },
              }
          )
          .then((res) => {
            console.log(res);
            this.$emit("changeImage", "reload");
            this.$emit("uploadedImage", res.data);
          })
          .catch((err) => {
            console.log(err);
              this.setModal({percent : 0, show : false})
              this.$emit("error");
          });
      }
    },
      
      setModal({percent, show}){
          this.modal.show = show
          this.modal.percent = percent
          this.modal.subtitle = percent === 100 ? this.$t("successfully_uploaded") : this.$t("uploading")
          if (this.modal.show === true && this.modal.percent >= 100){
              console.log(this.modal.percent)
              setTimeout(()=>{
                  this.modal.show = false
                  this.modal.percent = 0
              },500)
          }
      },
      
    verifyValue(item) {
      if (Array.isArray(this.valueLocal)) {
        return this.valueLocal.includes(item);
      }
      return false;
    },

    async validateMediaRes(event) {
      if (this.sizeValidation) {
        const size = this.sizeValidation.split('x');
        const requiredWidth = parseFloat(size[0].trim());
        const requiredHeight = parseFloat(size[1].trim());
        console.log('Required size:', requiredWidth, requiredHeight);

        const file = event.target.files[0];
        const fileType = file.type;

        let media;

        console.log('File type:', fileType);
        if (fileType.startsWith('video/webm')) {
          media = document.createElement('video');
        } else if (fileType.startsWith('image')) {
          media = new Image();
        } else {
          console.log('Unsupported file type');
          this.$emit('error', 'Unsupported file type');
          return false;
        }

        // Crear una URL de objeto para el archivo
        const objectURL = URL.createObjectURL(file);
        media.src = objectURL;

        // Función para liberar la URL del objeto después de la carga
        const revokeObjectURL = () => {
          URL.revokeObjectURL(objectURL);
        };

        return new Promise((resolve, reject) => {
          if (fileType.startsWith('video/webm')) {
            media.onloadedmetadata = () => {
              console.log('Video dimensions:', media.videoWidth, media.videoHeight);
              const width = media.videoWidth;
              const height = media.videoHeight;
              if (height !== requiredHeight || width !== requiredWidth) {
                const error = this.$t('media_size_wrong', { size: this.sizeValidation });
                console.log(error);
                this.$emit('error', error);
                this.imgFile = null;
                revokeObjectURL();
                resolve(false);
              } else {
                revokeObjectURL();
                resolve(true);
              }
            };
            media.onerror = () => {
              console.log('Error loading video');
              this.$emit('error', 'Error loading video');
              revokeObjectURL();
              resolve(false);
            };
          } else {
            media.onload = () => {
              console.log('Image dimensions:', media.width, media.height);
              const width = media.width;
              const height = media.height;
              if (height !== requiredHeight || width !== requiredWidth) {
                const error = this.$t('media_size_wrong', { size: this.sizeValidation });
                console.log(error);
                this.$emit('error', error);
                this.imgFile = null;
                revokeObjectURL();
                resolve(false);
              } else {
                revokeObjectURL();
                resolve(true);
              }
            };
            media.onerror = () => {
              console.log('Error loading image');
              this.$emit('error', 'Error loading image');
              revokeObjectURL();
              resolve(false);
            };
          }
        });
      }
      return true;
    },
    
     validateFormat(type) {
        return !(this.acceptOnly && !type.includes(this.acceptOnly));
     },

    reset() {
      this.$emit('reset');
      this.imgFile = null;
      this.valueLocal = '';
    },
      
  },
};
</script>
<style>
.dropdown-height{
    height: 300px !important;
    overflow: auto;
    text-align: center;
}
.preview-container {
  margin-bottom: 5px;
}
</style>
<script>
import { quillEditor } from "vue-quill-editor";
import axios from "axios";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BButton } from "bootstrap-vue";
import store from "@/store";

export default {
  components: {
    BButton,
    quillEditor,
    ImageFieldForm,
  },
  props: {
    mode: {
      type: String,
      default: "create", // Puede ser "create" o "edit"
    },
    id: {
      type: String,
      default: null, // Id de la página para el modo edición
    },
    allowedSections: {
      type: Array,
      default: () => ["header", "text", "gallery"],
    },
  },
  computed: {
    getWhitelabelSelect() {
      return store.state.whitelabelCurrencyNabvar.whitelabel;
    },
  },
  watch: {
    getWhitelabelSelect: {
      handler(newWhitelabel, oldWhitelabel) {
        if (newWhitelabel?._id !== oldWhitelabel?._id)
          this.$router.push({ name: "PageManager" });
      },
    },
  },
  data() {
    return {
      localeSlug: "",
      localeTitle: "",
      sections: [],
      selectedSection: null,
      quillContent: "",
      snowOption: {
        theme: "snow",
      },
      loading: false,
      fetchPageLoading: false,
      fetchPageError: "",
    };
  },
  created() {
    if (this.mode === "edit") {
      this.fetchPage();
    } else {
      this.localeSlug = "Nueva Página";
    }
  },
  methods: {
    async fetchPage() {
      try {
        this.fetchPageLoading = true;
        const response = await axios.get(
          `${process.env.VUE_APP_URL}/pages/${this.id}`
        );
        const data = response.data;
        this.localeSlug = data.slug;
        this.sections = data.sections;
        this.localeTitle = data.title;
      } catch (error) {
        this.$toast.error("Error al cargar la página.");
        this.fetchPageError = "Error to load the page";
      } finally {
        this.fetchPageLoading = false;
      }
    },

    addSection(type) {
      this.sections.push({ type, content: "" });
      this.$toast.success(`Sección ${type} añadida.`);
    },

    selectSection(index) {
      this.selectedSection = index;
      if (this.sections[index].type !== "gallery") {
        this.quillContent = this.sections[index]?.content || "";
      }
    },

    saveSectionContent() {
      if (
        this.selectedSection !== null &&
        this.sections[this.selectedSection].type !== "gallery"
      ) {
        this.sections[this.selectedSection].content = this.quillContent;
        this.$toast.success("Sección guardada.");
      }
      this.selectedSection = null;
    },

    deleteSection(index) {
      this.sections.splice(index, 1);
      this.selectedSection = null;
      this.quillContent = "";
      this.$toast.success("Sección eliminada.");
    },

    async editPage() {
      this.loading = true;
      const endpoint =
        this.mode === "edit" ? `/pages/edit/${this.id}` : `/pages/create`;
      try {
        await axios.post(process.env.VUE_APP_URL + endpoint, {
          id: this.id,
          sections: this.sections,
        });
        this.$toast.success("Página editada con éxito.");
        this.$router.push({ name: "PageManager" });
      } catch (error) {
        console.log(error);
        this.$toast.error("Error al editada la página.");
      } finally {
        this.loading = false;
      }
    },

    async createPage() {
      this.loading = true;
      const endpoint = `/pages`;
      try {
        await axios.post(process.env.VUE_APP_URL + endpoint, {
          slug: this.localeSlug,
          title: this.localeTitle,
          sections: this.sections,
        });
        this.$toast.success("Página guardada con éxito.");
        this.$router.push({ name: "PageManager" });
      } catch (error) {
        console.log(error);
        this.$toast.error("Error al guardar la página.");
      } finally {
        this.loading = false;
      }
    },

    savePage() {
      if (this.mode === "edit") this.editPage();
      else this.createPage();
    },

    showPagePreview() {
      this.$router.push({ name: "PageRender", params: { id: this.id } });
    },

    onUploadedImage(data) {
      if (this.selectedSection !== null && data?.files.length) {
        this.sections[this.selectedSection].content =
          data.files[data.files.length - 1]; // Guarda la imagen en la sección correspondiente
        this.$toast.success("Imagen guardada correctamente.");
      }
    },

    errorInLoadImage(error) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },

    resetData() {
      this.sections[this.selectedSection].conent = "";
    },
  },
};
</script>

<template>
  <div v-if="fetchPageLoading">
    <b-spinner small></b-spinner>
  </div>

  <div v-else-if="!fetchPageLoading && fetchPageError">
    {{ fetchPageError }}
  </div>

  <div v-else class="container">
    <!-- Slug -->
    <b-form-group label="Slug de la página">
      <b-form-input
        v-model="localeSlug"
        placeholder="Ingrese el slug"
      ></b-form-input>
    </b-form-group>

    <!-- Slug -->
    <b-form-group label="Titulo de la página">
      <b-form-input
        v-model="localeTitle"
        placeholder="Ingrese el titulo"
      ></b-form-input>
    </b-form-group>

    <!-- Add section button -->
    <b-dropdown text="Añadir Sección" variant="primary" class="mb-3">
      <b-dropdown-item
        v-for="type in allowedSections"
        :key="type"
        @click="addSection(type)"
      >
        {{ type.toUpperCase() }}
      </b-dropdown-item>
    </b-dropdown>

    <!-- Section list -->
    <b-card>
      <b-card-header>
        <h5>Secciones</h5>
      </b-card-header>
      <b-card-body>
        <b-list-group flush>
          <b-list-group-item
            v-for="(section, index) in sections"
            :key="index"
            @click="selectSection(index)"
            :class="{ active: index === selectedSection }"
            class="cursor-pointer"
            action
          >
            {{ section.type.toUpperCase() }}
            <b-button
              variant="danger"
              size="sm"
              class="float-right"
              @click.stop="deleteSection(index)"
            >
              Eliminar
            </b-button>
          </b-list-group-item>
        </b-list-group>
      </b-card-body>
    </b-card>

    <!-- Edit content -->
    <div v-if="selectedSection !== null" class="mt-4">
      <b-card>
        <b-card-header>
          <h5>Editor de Contenido</h5>
        </b-card-header>
        <b-card-body>
          <div v-if="sections[selectedSection].type === 'gallery'">
            <image-field-form
              :single-button="true"
              class="w-100"
              text="Images"
              :value="sections[selectedSection].content"
              path="main"
              @uploadedImage="onUploadedImage($event)"
              @error="errorInLoadImage"
              single-button="1905x1136"
              :preview="false"
            >
            </image-field-form>

            <div class="row" v-if="sections[selectedSection].content">
              <img
                style="max-height: 250px"
                :src="sections[selectedSection].content"
                alt="Uploaded Image"
                class="mt-1 rounded mx-auto"
              />
              <b-button
                class="close-btn"
                @click="resetData()"
                variant="outline-primary"
                size="sm"
              >
                X
              </b-button>
            </div>
          </div>

          <quill-editor v-else v-model="quillContent" :options="snowOption" />
        </b-card-body>
        <b-card-footer>
          <b-button variant="primary" @click="saveSectionContent">
            Guardar Sección
          </b-button>
        </b-card-footer>
      </b-card>
    </div>

    <!-- Save page button -->
    <div class="mt-4">
      <b-button variant="success" @click="savePage" :disabled="loading">
        {{ mode === "edit" ? "Actualizar Página" : "Crear Página" }}
      </b-button>

      <b-button variant="primary" @click="showPagePreview" :disabled="loading"
        >Ver Preview</b-button
      >
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@core/scss/vue/libs/quill.scss";

.active {
  background-color: #007bff;
  color: white;
}
</style>

import router from '@/router'
// eslint-disable-next-line object-curly-newline
import { reactive, getCurrentInstance, watch, toRefs } from '@vue/composition-api'
import axios from '@/libs/axios'
import i18n from '@/libs/i18n'
import { gTranslate } from '@/libs/google-translate'

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = route => {
  const { route: resolvedRoute } = router.resolve(route)
  return resolvedRoute.path === router.currentRoute.path
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

export const useRoute = () => {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')

  return vm.proxy.$route
}

export const whitelabelPassRules = (whitelabelSelected) => {
  const formatOfValidPassword = { required : true }
  if (whitelabelSelected?.passwordSettings){
    const passwordSetting = whitelabelSelected?.passwordSettings
    if (passwordSetting.maxLengthActive)
      formatOfValidPassword.max = passwordSetting.maxLength
    if (passwordSetting.minLengthActive)
      formatOfValidPassword.min = passwordSetting.minLength
    if (passwordSetting.requiredAlphaNumeric){
      formatOfValidPassword.strongPassword = true
    }
  }
  return formatOfValidPassword
}

export const downloadReportFile = ({url,filter,fileTitle}) => {

  return new Promise((resolve, reject) => {
    return axios.get(process.env.VUE_APP_URL + url, {params: filter, responseType: 'blob'})
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileTitle)
          document.body.appendChild(link)
          link.click()
          resolve(response)
        })
        .catch(error =>{
            console.log(error)
            reject(error)
        })
  })
}

//Translate texts with Google Translate and if the text is very large it divides
//it into pieces of up to 4900 words and then joins them. Using this method in
//the future can be changed to another translate method and does not affect the
// entire app
export const  translatableText = async ({ text, lang = null, canByTooLong = false , autoErrorHandle = true}) => {
  if (!text) return ''
  if (canByTooLong && text.length > 4900) {
    //breaks text into chunks of up to 4900 characters
    const fragments = [];
    for (let i = 0; i < text.length / 4900; i++) {
      let start = i * 4900;
      let end = start + 4900;
      let fragment = text.slice(start, end);
      fragments.push(await translatableText({ text : fragment, lang, autoErrorHandle }));
    }
    return fragments.join('')
  }
  if (autoErrorHandle){
    try {
      return await gTranslate(text, lang ?? i18n.locale)
    } catch (e){
      return e.text
    }
  } else {
    return await gTranslate(text, lang ?? i18n.locale)
  }
}

/**
 * This is just enhancement over Object.extend [Gives deep extend]
 * @param {target} a Object which contains values to be overridden
 * @param {source} b Object which contains values to override
 */
// export const objectExtend = (a, b) => {
//   // Don't touch 'null' or 'undefined' objects.
//   if (a == null || b == null) {
//     return a
//   }

//   Object.keys(b).forEach(key => {
//     if (Object.prototype.toString.call(b[key]) === '[object Object]') {
//       if (Object.prototype.toString.call(a[key]) !== '[object Object]') {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = b[key]
//       } else {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = objectExtend(a[key], b[key])
//       }
//     } else {
//       // eslint-disable-next-line no-param-reassign
//       a[key] = b[key]
//     }
//   })

//   return a
// }
